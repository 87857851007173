import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader, Grid, Link, Typography, Theme } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Header, Page } from '@backstage/core-components';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        height: '100%',
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    icon: {
        fontSize: '1rem',
        marginLeft: theme.spacing(0.5),
    },
}));

interface ExternalLink {
    title: string;
    url: string;
}

interface ResourceData {
    title: string;
    description: string;
    links: ExternalLink[];
}

const resourcesData: ResourceData[] = [
    {
        title: 'Car Sales',
        description:
            'The Car Sales Technology Radar is a tool to visualize and track the adoption of new technologies, tools, languages, and practices within our Car Sales ARTs. This project aims to provide a customizable and interactive interface to help teams make informed decisions about technology adoption.',
        links: [
            { title: 'Tech Radar', url: 'https://cs.techradar.porsche.digital' },
            { title: 'GitHub', url: 'https://github.com/porsche-customer/cs-technology-radar' },
        ],
    },
    {
        title: 'My Porsche',
        description: 'Welcome to the My Porsche Technology Radar.',
        links: [
            { title: 'Tech Radar', url: 'https://myp.techradar.porsche.digital' },
            { title: 'GitHub', url: 'https://github.com/porsche-customer/myp-technology-radar' },
        ],
    },
    {
        title: 'AI',
        description: 'Welcome to the AI Technology Radar project!',
        links: [
            { title: 'Tech Radar', url: 'https://ai-tech-radar.vercel.app' },
            { title: 'GitHub', url: 'https://github.com/porschedigital/ai-tech-radar' },
        ],
    },
];

const ExternalResourcesCard: React.FC<ResourceData> = ({ title, description, links }) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardHeader title={title} />
            <CardContent>
                <Typography variant='body2' color='textSecondary' paragraph>
                    {description}
                </Typography>
                <Grid container spacing={2}>
                    {links.map((link) => (
                        <Grid item xs={12} sm={6} key={link.url}>
                            <Link href={link.url} target='_blank' rel='noopener noreferrer' color='primary' className={classes.link}>
                                {link.title}
                                <OpenInNewIcon className={classes.icon} />
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
};

const Layout = styled.div`
    display: flex;
    padding: 16px;
`;

const LeftNav = styled.nav`
    position: sticky;
    top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 8px;
    margin-left: 32px;
    margin-right: 32px;
    align-self: flex-start;
`;

const NavTitle = styled.p`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: var(--space-sm);
`;

const NavLink = styled.span`
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
    font-size: calc(var(--md-typeset-font-size) * 0.9);
    &:hover {
        text-decoration: underline;
        color: lightskyblue;
    }
`;

const ContentContainer = styled.div`
    flex: 1;
    overflow-y: auto;
`;

export const TechRadarOverview: React.FC = () => {
    const [selectedResource, setSelectedResource] = useState(resourcesData[0]);

    const handleResourceChange = (resource: ResourceData) => {
        setSelectedResource(resource);
    };

    return (
        <Page themeId='documentation'>
            <Header
                title='Technology Radar'
                subtitle='The Technology Radar is a tool to visualize and track the adoption of new technologies, tools, languages, and practice. This is a collection of all Porsche Technology Radars.'
            />
            <Layout>
                <LeftNav>
                    <NavTitle>Resources</NavTitle>
                    {resourcesData.map((resource) => (
                        <NavLink key={resource.title} onClick={() => handleResourceChange(resource)}>
                            {resource.title}
                        </NavLink>
                    ))}
                </LeftNav>
                <ContentContainer>
                    <ExternalResourcesCard
                        title={selectedResource.title}
                        description={selectedResource.description}
                        links={selectedResource.links}
                    />
                </ContentContainer>
            </Layout>
        </Page>
    );
};
